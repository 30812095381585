<template>
	<v-card class="py-6 px-6">
		<div class="d-flex align-center justify-space-between mb-4">
			<h1 class="ma-0 heading mb-3">Services</h1>
		</div>
		<Search
			:initialData="services"
			:url="`/${$store.state.app.projectId}/services/search`"
			@search-result="updateService($event)"
		></Search>
	</v-card>
</template>

<script>
import Search from '@/components/Search'

export default {
	components: {
		Search,
	},

	data() {
		return {
			initData: this.services,
		}
	},
	props: {
		services: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		updateService(data) {
			this.initData = data
		},
		returnServiceArray() {
			return this.initData
			
		},
	},
}
</script>

<style scoped>
.heading {
	color: var(--blue);
	font-size: 1.3rem;
	font-weight: 400 !important;
}
.notice {
	font-size: 1rem;
	color: #979ba2;
}
</style>
