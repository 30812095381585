<template>
	<v-navigation-drawer
		v-model="drawer"
		fixed
		stateless
		temporary
		right
		class="about-drawer"
	>
		<div class="d-flex align-center header pt-2 px-2 pb-2">
			<span
				class="mdi mdi-chevron-left back-icon"
				@click="clear()"
			></span>
			<h1 class="crud-title ml-2">About</h1>
			<span class="mdi mdi-close back-icon" @click="clear()"></span>
		</div>
		<div class="pa-4">
			<h1 class="heading mb-1">Title</h1>
			<v-text-field
				solo
				flat
				dense
				placeholder="Enter the Title for this Section of the Home page."
				outlined
				:error-messages="errors.title"
				v-model="title"
			></v-text-field>
			<h1 class="heading mb-1">Image</h1>
			<FileUploader
				:initFiles="banner ? [banner] : []"
				@errorOccured="dropzoneError"
				:purpose="`banner`"
				:maxFiles="1"
				@changed="fileUploaderChanged"
			/>

			<h1 class="heading mb-1">Content</h1>
			<div id="ckcontent" class="mb-10">
				<ckeditor
					:initData="content"
					purpose="homepage about"
					@changed="editorChanged"
				></ckeditor>
				<span class="error-msg">
					{{ errors.content }}
				</span>
			</div>
			<v-footer absolute class="footer">
				<v-btn @click="validateContent()" depressed class="save"
					>Save</v-btn
				></v-footer
			>
		</div>
	</v-navigation-drawer>
</template>
<script>
import Editor from '@/components/CkEditor'
import FileUploader from '@/components/FileUploader'

export default {
	components: {
		ckeditor: Editor,
		FileUploader,
	},
	props: {
		initialData: {
			type: Object,
			required: true,
			default: () => {},
		},
	},
	data() {
		return {
			content: '',
			drawer: true,
			title: '',
			banner: null,
			bannerId: null,
			errors: {
				title: '',
				content: '',
			},
		}
	},
	created() {
		if (Object.keys(this.initialData).length > 0) {
			this.content = this.initialData.content
			this.title = this.initialData.title
			this.banner = this.initialData.banner
			this.bannerId = this.initialData.banner
				? this.initialData.banner.media
				: null
		}
	},
	watch: {
		title() {
			return (this.errors.title = '')
		},
		content() {
			return (this.errors.content = '')
		},
	},
	methods: {
		editorChanged(body) {
			this.content = body
		},
		dropzoneError(val) {
			console.log(val)
		},
		fileUploaderChanged(val) {
			if (val.length != 0) {
				var data = val[0]
				this.bannerId = data.id
				this.purpose = 'banner'
				this.banner = {
					id: this.bannerId,
					publicURL: data.publicURL,
				}
			} else {
				this.bannerId = null
				this.banner = null
			}
		},
		validateContent() {
			let isValid = true
			if (this.title === '') {
				;(isValid = false),
					(this.errors.title =
						'Please Enter a Suitable Title for this Section')
			} else {
				this.errors.title = ''
			}
			if (this.content === '') {
				;(isValid = false),
					(this.errors.content = 'Please Enter Content')
			} else {
				this.errors.content = ''
			}
			if (isValid) {
				this.saveContent()
			}
		},
		saveContent() {
			let aboutSection = {
				title: this.title,
				banner: this.bannerId,
				content: this.content,
			}

			this.$emit('on-about-update', aboutSection)
		},
		clear() {
			;(this.errors = {
				title: '',
				content: '',
			}),
				this.$emit('close-drawer')
		},
	},
}
</script>
<style scoped>
.crud-title {
	font-size: 1.3rem !important;
}
.heading {
	font-size: 1.2rem;
	font-weight: 400;
}
.edit {
	color: var(--blue) !important;
}
.about-drawer {
	width: 50% !important;
}
.about-drawer .header {
	border-bottom: 1px solid #eee;
}
.about-drawer .back-icon {
	font-size: 25px;
	cursor: pointer;
}
.about-drawer .crud-title {
	width: 100%;
}
.about-drawer .close-icon {
	font-size: 22px;
	cursor: pointer;
}
.footer {
	background-color: var(--blue);
	height: 47px;
}
.save {
	background-color: var(--blue) !important;
	color: white;
	width: 100% !important;
	height: 100% !important;
}
.text-wrap {
	float: right;
	margin: 0px 0px 10px 10px;
}
.about .title {
	font-size: 23px;
}
</style>
