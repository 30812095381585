<template>
	<div v-if="content != null || content !== ''">
		<div class="page-content" v-html="content"></div>
	</div>
</template>
<script>
export default {
	props: {
		content: {
			type: String,
			required: false,
		},
	},
}
</script>

<style scoped>
.page-content >>> h6,
.page-content >>> h5,
.page-content >>> h4,
.page-content >>> h3,
.page-content >>> h2,
.page-content >>> h1 {
	margin: 0.7rem 0 10px 0;
	font-size: 1.6rem;
	color: rgba(0, 0, 0, 0.87) !important;
}
.page-content >>> p {
	margin-top: 0px !important;
	font-size: 17px;
	line-height: 1.6;
	text-align: left;
	margin-bottom: 0px !important;
}
.page-content >>> ol,
.page-content >>> ul {
	font-size: 17px;
	line-height: 1.6;
	text-align: left;
	padding-left: 18px !important;
}
.page-content >>> ol li,
.page-content >>> ul li {
	padding-left: 5px;
	margin-bottom: 0px !important;
}
.page-content >>> ul li {
	list-style-type: disc;
}
.page-content >>> img {
	max-width: 60%;
	max-height: 450px;
}
.page-content >>> .image {
	display: flex;
	justify-content: center;
}
.page-content >>> blockquote {
	margin: 20px 0;
	padding-left: 20px;
	border-left: 4px solid var(--green);
	font-size: 1.25rem;
	font-weight: 200;
}
.page-content >>> a {
	color: var(--green) !important;
}
.page-content >>> a:hover {
	background-color: rgba(69, 204, 74, 0.15);
}
.page-content >>> ul > li {
	list-style-type: disc;
}
.page-content >>> table th {
	color: var(--green);
}
.page-content >>> table {
	border: 1px solid #e0e0e0;
}
.page-content >>> table th,
td {
	text-align: center;
}
.page-content >>> figure.table {
	margin: 15px auto !important;
	width: 80%;
}
</style>
