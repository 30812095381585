<template>
	<v-card>
		<div>
			<div
				class="
					d-flex
					flex-row
					justify-space-between
					align-end
					py-4
					px-6
				"
			>
				<h1 class="crud-title">Banner</h1>
				<v-btn
					fab
					small
					color="white"
					dark
					elevation="0"
					right
					class="edit"
					absolute
					@click="showDrawer = !showDrawer"
				>
					<v-icon> mdi-pencil </v-icon>
				</v-btn>
			</div>
			<v-divider></v-divider>
			<v-card
				class="ma-0"
				v-if="banner.image || banner.heading || banner.tagLine"
			>
				<div
					class="d-flex align-center justify-center"
					v-if="banner.image !== null && banner.image.publicURL"
				>
					<v-img
						class="white--text align-end"
						height="200px"
						:src="banner.image.publicURL"
					>
					</v-img>
				</div>
				<div
					:class="[
						'd-flex flex-column pa-4',
						banner.image !== null && banner.image.publicURL
							? 'left'
							: 'align-baseline',
					]"
				>
					<h1 class="banner-heading" v-if="banner.heading">
						{{ banner.heading }}
					</h1>
					<p v-if="banner.tagLine">
						{{ banner.tagLine }}
					</p>
					<div>
						<v-btn
							depressed
							color="var(--blue)"
							class="banner-btn"
							v-if="banner.button.label"
						>
							{{ banner.button.label }}
						</v-btn>
					</div>
				</div>
			</v-card>
		</div>
		<BannerSectionDrawer
			v-if="showDrawer"
			@on-banner-update="updateBanner($event)"
			@close-drawer="closeDrawer()"
			:initial-data="banner"
		></BannerSectionDrawer>
	</v-card>
</template>
<script>
import BannerSectionDrawer from './BannerSectionDrawer'
export default {
	props: {
		initialData: {
			type: Object,
			required: false,
		},
	},
	components: {
		BannerSectionDrawer,
	},
	data() {
		return {
			banner: {},
			showDrawer: false,
		}
	},
	watch: {
		initialData() {
			this.banner = this.initialData
		},
	},
	methods: {
		updateBanner(emittedObj) {
			this.showDrawer = !this.showDrawer
			const banner= {
					heading: emittedObj.heading,
					tagLine: emittedObj.tagLine,
					button: emittedObj.button,
					image: emittedObj.image,
				}
			this.$emit('banner-update',banner)
		},
		closeDrawer() {
			this.showDrawer = !this.showDrawer
		},
	},
}
</script>
<style scoped>
.crud-title {
	font-size: 1.3rem !important;
}
.heading {
	font-size: 1.2rem;
	font-weight: 400;
}
.edit {
	color: var(--blue) !important;
}
.banner-img {
	max-height: 250px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}
.left {
	position: absolute;
	bottom: 8px;
	left: 16px;
}
.banner-heading {
	font-size: 1.5rem;
}
.banner-btn {
	color: white !important;
}
</style>
