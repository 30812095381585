<template>
	<v-card>
		<div>
			<div
				class="
					d-flex
					flex-row
					justify-space-between
					align-end
					py-4
					px-6
				"
			>
				<h1 class="crud-title">About</h1>
				<v-btn
					fab
					small
					color="white"
					dark
					elevation="0"
					right
					class="edit"
					absolute
					@click="showAddAboutTextDrawer = !showAddAboutTextDrawer"
				>
					<v-icon> mdi-pencil </v-icon>
				</v-btn>
			</div>
			<v-divider v-if="about.title"></v-divider>
			<v-row class="mx-0 py-4 px-6" v-if="about.title">
				<v-col cols="12" class="pa-0">
					<v-col
						v-if="about.banner"
						cols="3"
						class="pa-0 text-wrap d-flex justify-end"
					>
						<img :src="about.banner.publicURL" width="100%" />
					</v-col>
					<div class="about">
						<h1 class="title mb-2">
							{{ about.title }}
						</h1>
						<HtmlContent :content="about.content"></HtmlContent>
					</div>
				</v-col>
			</v-row>
		</div>
		<AboutContentDrawer
			v-if="showAddAboutTextDrawer"
			@on-about-update="updateAbout($event)"
			@close-drawer="closeDrawer()"
			:initialData="about"
		></AboutContentDrawer>
	</v-card>
</template>
<script>
import HtmlContent from '@/components/HtmlContent'
import AboutContentDrawer from './AboutContentDrawer'
export default {
	props: {
		initialData: {
			type: Object,
			required: false,
		},
	},
	components: {
		HtmlContent,
		AboutContentDrawer,
	},
	data() {
		return {
			about: {},
			showAddAboutTextDrawer: false,
		}
	},
	watch: {
		initialData() {
			this.about = this.initialData
		},
	},
	methods: {
		updateAbout(emittedObject) {
			this.showAddAboutTextDrawer = !this.showAddAboutTextDrawer
			this.about = emittedObject
			this.$emit('on-about-update', emittedObject)
		},
		closeDrawer() {
			this.showAddAboutTextDrawer = !this.showAddAboutTextDrawer
		},
	},
}
</script>
<style scoped>
.crud-title {
	font-size: 1.3rem !important;
}
.heading {
	font-size: 1.2rem;
	font-weight: 400;
}
.edit {
	color: var(--blue) !important;
}
.about-drawer {
	width: 50% !important;
}
.about-drawer .header {
	border-bottom: 1px solid #eee;
}
.about-drawer .back-icon {
	font-size: 25px;
	cursor: pointer;
}
.about-drawer .crud-title {
	width: 100%;
}
.about-drawer .close-icon {
	font-size: 22px;
	cursor: pointer;
}
.footer {
	background-color: var(--blue);
	height: 47px;
}
.save {
	background-color: var(--blue) !important;
	color: white;
	width: 100% !important;
	height: 100% !important;
}
.text-wrap {
	float: right;
	margin: 0px 0px 10px 10px;
}
.about .title {
	font-size: 23px;
}
</style>
