<template>
	<v-navigation-drawer
		v-model="drawer"
		fixed
		stateless
		temporary
		right
		class="banner-drawer"
	>
		<div class="d-flex align-center header pt-2 px-2 pb-2">
			<span
				class="mdi mdi-chevron-left back-icon"
				@click="clear()"
			></span>
			<h1 class="crud-title ml-2">Banner</h1>
			<span class="mdi mdi-close back-icon" @click="clear()"></span>
		</div>
		<div class="pa-4">
			<h1 class="heading mb-1">Heading</h1>
			<v-text-field
				solo
				flat
				dense
				placeholder="Enter a tagLine."
				outlined
				v-model="heading"
			></v-text-field>
			<h1 class="heading mb-1">TagLine</h1>
			<v-text-field
				solo
				flat
				dense
				placeholder="Enter a tagLine."
				outlined
				v-model="tagLine"
			></v-text-field>
			<h1 class="heading mb-1">Image</h1>
			<FileUploader
				:initFiles="banner ? [banner] : []"
				@errorOccured="dropzoneError"
				:purpose="`banner`"
				:maxFiles="1"
				@changed="fileUploaderChanged"
			/>

			<h1 class="heading mb-1">Button Text</h1>
			<v-text-field
				solo
				flat
				dense
				placeholder="Enter text for button."
				outlined
				v-model="button.label"
			></v-text-field>
			<h1 class="heading mb-1">Button Path</h1>
			<v-text-field
				solo
				flat
				dense
				placeholder="Enter text for button."
				outlined
				v-model="button.path"
				class="mb-8"
			></v-text-field>
			<v-footer absolute class="footer">
				<v-btn @click="validateData()" depressed class="save"
					>Save</v-btn
				></v-footer
			>
		</div>
	</v-navigation-drawer>
</template>
<script>
import FileUploader from '@/components/FileUploader'

export default {
	components: {
		FileUploader,
	},
	props: {
		initialData: {
			type: Object,
			required: true,
			default: () => {},
		},
	},
	data() {
		return {
			drawer: true,
			heading: '',
			tagLine: '',
			button: {
				label: null,
				path: null,
			},
			banner: null,
			bannerId: null,
		}
	},
	created() {
		if (Object.keys(this.initialData).length > 0) {
			this.tagLine = this.initialData.tagLine
			this.heading = this.initialData.heading
			this.button = this.initialData.button
			this.banner = this.initialData.image ? this.initialData.image : null
			this.bannerId =
				this.initialData.image && this.initialData.image.media
					? this.initialData.image.media
					: null
		}
	},
	methods: {
		editorChanged(body) {
			this.content = body
		},
		dropzoneError(val) {
			console.log(val)
		},
		fileUploaderChanged(val) {
			if (val.length != 0) {
				var data = val[0]
				this.bannerId = data.id
				this.purpose = 'banner'
				this.banner = {
					id: this.bannerId,
					publicURL: data.publicURL,
				}
			} else {
				this.banner = null
				this.bannerId = null
			}
		},
		validateData() {
			let isValid = true
			if (this.button.label !== null && this.button.path === null) {
				this.errors.path = 'Please Enter a valid Path'
				isValid = false
			} else if (
				this.button.path !== null &&
				this.button.label === null
			) {
				this.errors.path = 'Please Enter a Label'
				isValid = false
			} else {
				isValid = true
			}
			if (isValid) {
				this.saveContent()
			}
		},
		saveContent() {
			let bannerSection = {
				tagLine: this.tagLine,
				image: this.bannerId ? this.bannerId : null,
				button: this.button,
				heading: this.heading,
			}
			this.$emit('on-banner-update', bannerSection)
		},
		clear() {
			this.$emit('close-drawer')
		},
	},
}
</script>
<style scoped>
.crud-title {
	font-size: 1.3rem !important;
}
.heading {
	font-size: 1.2rem;
	font-weight: 400;
}
.edit {
	color: var(--blue) !important;
}
.banner-drawer {
	width: 50% !important;
}
.banner-drawer .header {
	border-bottom: 1px solid #eee;
}
.banner-drawer .back-icon {
	font-size: 25px;
	cursor: pointer;
}
.banner-drawer .crud-title {
	width: 100%;
}
.banner-drawer .close-icon {
	font-size: 22px;
	cursor: pointer;
}
.footer {
	background-color: var(--blue);
	height: 47px;
}
.save {
	background-color: var(--blue) !important;
	color: white;
	width: 100% !important;
	height: 100% !important;
}
.text-wrap {
	float: right;
	margin: 0px 0px 10px 10px;
}
.about .title {
	font-size: 23px;
}
</style>
