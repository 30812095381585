<template>
	<v-card class="py-6 px-6">
		<div class="d-flex align-center justify-space-between mb-4">
			<h1 class="ma-0 heading mb-3">Faqs ({{ faqs.length }})</h1>
			<v-btn depressed class="add" @click="addFaq()"> + Add FAQ </v-btn>
		</div>
		<draggable v-model="faqs" class="drag-area" ghost-class="ghost">
			<transition-group name="sortable">
				<ListOfFaqs
					v-for="faq in faqs"
					:key="faq.id"
					:faq="faq"
					@edit-faq="editFaq($event)"
					@remove-faq="removeFaq($event)"
				></ListOfFaqs>
			</transition-group>
		</draggable>
		<v-navigation-drawer
			v-model="drawer"
			fixed
			stateless
			temporary
			right
			class="faq-drawer"
			v-if="showCreateFaq"
		>
			<div class="d-flex align-center header pt-2 px-2 pb-2">
				<span
					class="mdi mdi-chevron-left back-icon"
					@click="closeDrawer()"
				></span>
				<h1 class="crud-title ml-2">Create Faq</h1>
				<span
					class="mdi mdi-close back-icon"
					@click="closeDrawer()"
				></span>
			</div>
			<CreateFaq
				:faq="faq"
				@saved-faq="updateFaqs($event)"
				class="px-4"
			></CreateFaq>
		</v-navigation-drawer>
	</v-card>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'

import draggable from 'vuedraggable'

import CreateFaq from '@/components/Faq/CreateFaq'
import ListOfFaqs from '@/components/Faq/ListOfFaqs'

export default {
	components: {
		CreateFaq,
		ListOfFaqs,
		draggable,
	},
	props: {
		initialData: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			drawer: true,
			faqs: [],
			showCreateFaq: false,
			faq: {},
		}
	},
	watch: {
		initialData() {
			if (this.faqs !== this.initialData) {
				return (this.faqs = this.initialData)
			}
		},
		// faqs() {
		// 	this.faqs.forEach((faq) => {
		// 		console.log(faq)
		// 		if (faq.question !== '') {
		// 			return this.$emit('on-faq-update', this.faqs)
		// 		}
		// 	})
		// },
	},
	methods: {
		addFaq() {
			this.faq = {
				question: '',
				answer: '',
				id: uuidv4(),
			}
			let arr = this.faqs
			arr.push(this.faq)
			this.faqs = arr
			this.showCreateFaq = true
		},
		updateFaqs(emittedObj) {
			let faqs = this.faqs
			faqs.every(function (faq, index) {
				if (faq.id === emittedObj.id) {
					let obj = {
						question: emittedObj.question,
						answer: emittedObj.answer,
						id: emittedObj.id,
					}
					faqs[index] = obj
					// vm.$set(vm.facilities, index, obj);
					return false // to break the loop
				} else {
					return true
				}
			})
			this.faqs = faqs
			this.showCreateFaq = false
			this.$emit('on-faq-update', this.faqs)
		},
		removeFaq(faqId) {
			let arr = this.faqs
			arr.forEach((faq, index) => {
				if (faq.id === faqId) {
					arr.splice(index, 1)
					return false // to break the loop
				} else {
					return true
				}
			})
			this.faqs = arr
			this.$emit('on-faq-update', this.faqs)
		},
		editFaq(faq) {
			this.showCreateFaq = true
			this.faq = faq
		},
		closeDrawer() {
			this.showCreateFaq = !this.showCreateFaq
			if (this.faq.question === '') {
				this.removeFaq(this.faq.id)
			}
		},
	},
}
</script>
<style scoped>
.heading {
	color: var(--blue);
	font-size: 1.3rem;
	font-weight: 400 !important;
}
.add {
	background-color: var(--blue) !important;
	color: white;
}
.faq-drawer {
	width: 40% !important;
	height: 100% !important;
}
.faq-drawer .header {
	border-bottom: 1px solid #eee;
}
.faq-drawer .back-icon {
	font-size: 25px;
	cursor: pointer;
}
.faq-drawer .crud-title {
	width: 100%;
}
.faq-drawer .close-icon {
	font-size: 22px;
	cursor: pointer;
}
.sortable-move {
	transition: transform 1s;
}
</style>
