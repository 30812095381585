<template>
	<div>
		<div class="pt-2 pb-1 px-6 header">
			<h1 class="crud-title">Home Page Content</h1>
		</div>

		<div class="pa-6">
			<BannerSection
				@banner-update="saveBanner($event)"
				:initial-data="banner"
			></BannerSection>

			<About
				@on-about-update="saveAbout($event)"
				:initialData="about"
			></About>

			<Faq :initial-data="faqs" @on-faq-update="saveFaq($event)"></Faq>

			<Service ref="services" :services="services"></Service>

			<h1 class="heading mb-1">Google Site Verification (Meta tag)</h1>
			<v-text-field
				solo
				flat
				dense
				placeholder="Enter the Google site verification content for the Home page."
				outlined
				hint="For example - xxxxx-xxxxxxx-xxxxxxx-xxxxxx"
				persistent-hint
				v-model="googleSiteVerification"
			></v-text-field>
			<UnoSaveButton
				@click.native="saveData()"
				@primary-button-click="dashboard()"
				primary-button-text="Move To Dashboard"
				secondary-button-text="Continue Editing"
				:alert="alert"
				:saving="isSaving"
			></UnoSaveButton>
		</div>
	</div>
</template>

<script>
import About from './components/About'
import Faq from './components/Faq'
import BannerSection from './components/BannerSection'
import UnoSaveButton from '@/components/Buttons/UnoSaveButton'
import Service from './components/Service'
export default {
	components: {
		About,
		Faq,
		BannerSection,
		Service,
		UnoSaveButton,
	},
	data() {
		return {
			googleSiteVerification: null,
			about: {},
			faqs: [],
			services: [],
			banner: {},
			alert: {
				show: null,
				message: null,
				error: false,
			},
			isSaving: false,
		}
	},
	created() {
		this.getHomePageData()
	},
	methods: {
		// servicesChanged(data) {
		// 	const updatedServices = {
		// 		services: data,
		// 	}
		// const projectId = this.$store.state.app.projectId
		// this.axios({
		// 	method: 'patch',
		// 	url: `/${projectId}/pages/home`,
		// 	data: updatedServices,
		// })
		// 	.then((res) => {
		// 		if (res.status === 404) {
		// 			console.log(404)
		// 		}
		// 		if (res.status == 200 && res.data.success) {
		// 			const data = res.data.data.meta
		// 			this.services = data.services
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		const { data } = error.response
		// 		if (data.statusCode == 400) {
		// 			this.$snackbar.notify({
		// 				message: data.message,
		// 				color: 'black',
		// 			})
		// 		}
		// 	})
		// },

		getHomePageData() {
			const projectId = this.$store.state.app.projectId
			this.axios.get(`/${projectId}/pages/home`).then((res) => {
				if (res.status === 404) {
					console.log(404)
				}
				const data = res.data.meta
				this.setHomePageData(data)
			})
		},
		setHomePageData(homeData) {
			this.about = homeData.about
			this.faqs = homeData.faqs
			this.banner = homeData.banner
			this.services = homeData.services
				? homeData.services.filter((service) => service != null)
				: []
			this.googleSiteVerification = homeData.googleSiteVerification
		},
		saveAbout(emittedObj) {
			this.about = emittedObj
		},
		saveFaq(emittedArr) {
			this.faqs = emittedArr
		},
		saveBanner(emittedObj) {
			this.banner = emittedObj
			this.saveData()
		},
		dashboard() {
			this.$router.push({
				name: 'dashboard',
			})
		},
		saveData() {
			const services = this.$refs.services.returnServiceArray()
			const payload = {
				googleSiteVerification:
					this.googleSiteVerification === ''
						? null
						: this.googleSiteVerification,
				about: this.about,
				faqs: this.faqs,
				services,
				banner: {
					...this.banner,
					image:
						typeof this.banner.image === 'object'
							? this.banner.image.media
							: this.banner.image,
				},
			}
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'patch',
				url: `/${projectId}/pages/home`,
				data: payload,
			})
				.then((res) => {
					if (res.status === 404) {
						console.log(404)
					}

					if (res.status == 200 && res.data.success) {
						this.alert.message = res.data.message
						this.setHomePageData(res.data.data.meta)
					}
				})
				.catch((error) => {
					const { data } = error.response
					if (data.statusCode == 400) {
						this.$snackbar.notify({
							message: data.message,
							color: 'black',
						})
					}
				})
		},
	},
}
</script>

<style scoped>
.header {
	border-bottom: 1px solid #eee;
}
.heading {
	font-size: 1.2rem;
	font-weight: 600;
	color: #303f9f;
}
</style>
