<template>
	<v-card class="px-4 py-2 faq-card mb-2">
		<div class="d-flex align-start justify-space-between">
			<h1 class="section-heading font-weight-medium">
				{{ faq.question }}
			</h1>
			<div class="d-flex">
				<span
					class="mdi mdi-pencil edit mr-2"
					@click="$emit('edit-faq', faq)"
				></span>
				<span
					class="mdi mdi-close-circle remove"
					@click="$emit('remove-faq', faq.id)"
				></span>
			</div>
		</div>
		<HtmlContent :content="faq.answer"></HtmlContent>
	</v-card>
</template>
<script>
import HtmlContent from '../HtmlContent'
export default {
	props: {
		faq: {
			type: Object,
			required: true,
		},
	},
	components: {
		HtmlContent,
	},
}
</script>
<style scoped>
.section-heading {
	font-size: 1.2rem;
}
.remove {
	font-size: 1.5rem;
	color: #f44336;
	cursor: pointer;
}
.edit {
	font-size: 1.3rem;
	color: var(--blue);
	cursor: pointer;
}
.faq-card {
	cursor: all-scroll;
}
</style>
