<template>
	<div>
		<v-card-text class="px-1 mb-10">
			<v-row no-gutters>
				<v-col cols="12" class="mb-5">
					<p class="ma-0 label mb-4">Question:</p>
					<v-textarea
						solo
						flat
						name="question"
						no-resize
						outlined
						v-model="question"
						placeholder="Enter Question"
						:error-messages="errors.question"
					></v-textarea>
				</v-col>
				<v-col cols="12">
					<p class="ma-0 label mb-5">Answer:</p>
					<div id="ckcontent">
						<ckeditor
							:purpose="`create faq`"
							@changed="editorChanged"
							:initData="answer"
						></ckeditor>
					</div>
					<span class="error-msg">
						{{ errors.answer }}
					</span>
				</v-col>
			</v-row>
		</v-card-text>
		<div class="footer-container">
			<v-footer absolute class="footer">
				<v-btn @click="saveFaq()" depressed class="save"
					>Save</v-btn
				></v-footer
			>
		</div>
	</div>
</template>
<script>
import Editor from '@/components/CkEditor'

export default {
	components: {
		ckeditor: Editor,
	},
	props: {
		faq: {
			type: Object,
			required: false,
		},
	},
	data() {
		return {
			loading: false,
			faqId: null,
			isPublished: true,
			isEdit: false,
			question: '',
			answer: '',
			errors: {
				question: '',
				answer: '',
			},
			alert: {
				show: null,
				message: null,
				error: false,
			},
		}
	},
	watch: {
		question() {
			this.errors.question = ''
		},
		answer() {
			this.errors.answer = ''
		},
	},
	created() {
		if (Object.keys(this.faq).length > 0) {
			this.question = this.faq.question
			this.answer = this.faq.answer
			this.faqId = this.faq.id
		}
	},
	methods: {
		editorChanged(data) {
			this.answer = data
		},
		saveFaq() {
			let faq = {
				question: this.question,
				answer: this.answer,
				id: this.faqId,
			}
			this.$emit('saved-faq', faq)
		},
	},
}
</script>
<style scoped>
.label {
	font-size: 1.1rem;
}
.headline {
	color: #303f9f !important;
	font-size: 24px !important;
}
.underline {
	border-bottom: 3px solid #8bc34a;
	margin-top: 10px;
	width: 40px;
	display: block;
}
.footer {
	background-color: var(--blue);
	height: 50px;
}
.save {
	background-color: var(--blue) !important;
	color: white;
	width: 100% !important;
	height: 100% !important;
}
</style>
